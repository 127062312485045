<template>
    <a href="tel:+33672424728" class="phone-icon" aria-label="Call Nathan Tournache">
      <img src="@/assets/phone-icon.png" alt="Phone" />
    </a>
  </template>
  
  <script>
  export default {
    name: "PhoneButton",
  };
  </script>
  
  <style scoped>
  .phone-icon {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 20;
  }
  
  .phone-icon img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e2d7d7;
    padding: 10px;
  }
  </style>
  
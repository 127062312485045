<template>
  <div class="home-container">
    <!-- Navbar Component -->
    <AppNavbar />

    <!-- Phone Button Component -->
    <PhoneButton />

    <!-- Buttons -->
    <div class="button-container">
      <router-link to="/skills" class="button">Compétences</router-link>
      <router-link to="/projects" class="button">Projets</router-link>
      <router-link to="/career" class="button">Parcours</router-link>
    </div>
  </div>
</template>

<script>
import AppNavbar from "@/components/AppNavbar.vue";
import PhoneButton from "@/components/PhoneButton.vue";

export default {
  name: "HomePage",
  components: {
    AppNavbar,
    PhoneButton
  }
};
</script>

<style scoped>
/* Global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

/* Button container */
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh; /* Full screen height */
  padding: 0 50px;
}

.button {
  flex: 1;
  margin: 10px;
  padding: 20vh;
  background-color: #2D2D2D;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #1A1A1A;
}

/* Version mobile */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    justify-content: space-evenly; /* Répartir uniformément les boutons */
    margin-top: 20vh;
    height: 50vh;
    padding: 10px 20px; /* Garder un bon espacement latéral */
  }

  .button {
    width: 100%; /* Occupe toute la largeur disponible */
    max-width: 90%; /* Limite la largeur pour garder un espace */
    margin: 10px; /* Centre horizontalement */
    padding: 10px 0; /* Ajuster la hauteur verticale */
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import Skills from '../views/SkillsSection.vue';
import Projects from '../views/ProjectsSection.vue';
import Career from '../views/CareerPath.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/skills', component: Skills },
  { path: '/projects', component: Projects },
  { path: '/career', component: Career },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

export const projects = [
  {
    id: 5,
    title: "Analyse du sentiment utilisateur",
    description: "Développement d'une solution d'analyse de sentiment pour classifier les avis clients en catégories positives, neutres ou négatives. Le projet utilise Python et PyTorch pour entraîner un modèle de machine learning. Une interface utilisateur créée avec Flask permet de soumettre des avis et de visualiser les résultats sous forme de graphiques générés avec Matplotlib. Ce projet combine des techniques avancées de traitement du langage naturel (NLP) et une expérience utilisateur soignée pour offrir une analyse en temps réel.",
    githubLink: "https://github.com/mon-compte/ml-project",
    siteLink: "https://mon-ml-project.com",
    images: ["project5-image1.jpg", "project5-image2.jpg"],
    keywords: ["flask", "machine learning", "python", "backend", "data science"]
  },
  {
    id: 3,
    title: "Robot sumo : de la conceptualisation à la compétition",
    description: "Réalisation d'un robot sumo autonome, de la conception à la compétition. Utilisation d'Arduino pour la programmation embarquée, avec un algorithme optimisé pour détecter et réagir rapidement face à l'adversaire. Gestion complète de projet en équipe, incluant le prototypage, l'intégration des capteurs, la fabrication, et des tests rigoureux pour garantir la fiabilité en situation réelle. Ce projet met en avant mes compétences en robotique, en gestion de projet et en résolution de problèmes techniques complexes.",
    githubLink: "https://github.com/mon-compte/ml-project",
    siteLink: "https://mon-ml-project.com",
    images: ["project3-image1.jpg", "project3-image2.jpg", "project3-image3.jpg", "project3-image4.jpg"],
    keywords: ["arduino", "python", "gestion de projet", "robotique"]
  },
  {
    id: 1,
    title: "Portfolio en vue.js",
    description: "Développement d'un portfolio personnel utilisant Vue.js pour les interfaces dynamiques. Le projet intègre JavaScript pour les fonctionnalités interactives et un design responsive grâce à CSS. Le backend est géré avec Node.js pour le stockage des données et le suivi des visites. Le portfolio met en avant mes compétences en développement full-stack et propose une navigation fluide avec des animations modernes.",
    githubLink: "https://github.com/mon-compte/vuejs-project",
    siteLink: "https://mon-site-vuejs.com",
    images: ["project1-image1.jpg", "project1-image2.jpg"],
    keywords: ["vue", "javascript", "responsive", "node.js", "frontend"]
  },
  {
    id: 2,
    title: "Affluence des piscines",
    description: "Conception et développement d'une interface interactive pour afficher l'affluence en temps réel des piscines de Bordeaux Métropole. Développé en Vue.js, ce projet est déployé sur des écrans dédiés dans les piscines ainsi que sur le site web de la métropole. Tests et automatisations réalisés en PHP pour garantir une expérience utilisateur fluide et une gestion des données optimisée. Ce projet assure une consultation rapide et intuitive pour les usagers.",
    githubLink: "https://github.com/mon-compte/python-flask-api",
    siteLink: "https://mon-api-flask.com",
    images: ["project2-image1.jpg"],
    keywords: ["vue", "javascript", "responsive", "php", "frontend"]
  },
  {
    id: 4,
    title: "Application web de gestion des ventes",
    description: "Création d'une application web pour le suivi et la gestion des ventes. Utilisation de Flask pour le backend et MySQL pour le stockage des données. Les données sont analysées avec NumPy et visualisées via Matplotlib pour offrir des graphiques dynamiques. L'application inclut des fonctionnalités CRUD, une validation des entrées utilisateurs, et un design responsive conçu avec Bootstrap, permettant une navigation fluide sur tous types d'écrans.",
    githubLink: "https://github.com/mon-compte/ml-project",
    siteLink: "https://mon-ml-project.com",
    images: ["project4-image1.jpg", "project4-image2.jpg", "project4-image3.jpg"],
    keywords: ["flask", "python", "data science", "backend"]
  },
];

<template> 
  <div class="career-path">
    <!-- Affichage du PDF -->
    <iframe 
      :src="pdfUrl" 
      class="pdf-viewer" 
      type="application/pdf">
    </iframe>
  </div>
</template>

<script>
export default {
  name: "CareerPath",
  data() {
    return {
      pdfUrl: new URL('@/assets/files/mon-pdf.pdf', import.meta.url).href, // Correct avec Vue CLI
    };
  },
};
</script>

<style scoped>
.career-path {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh; /* Prend toute la hauteur de la fenêtre */
  padding-top: 45px; /* Ajustez cette valeur en fonction de la hauteur réelle de la navbar */
  box-sizing: border-box;
  overflow: hidden; /* Assure qu'aucun débordement ne se produit */
}

h1 {
  margin-top: 0;
  font-size: 1.5em;
  text-align: center;
  color: white;
}

.pdf-viewer {
  width: 100%; /* Toujours s'adapter à la largeur de l'écran */
  height: calc(100vh - 45px); /* Hauteur totale moins la hauteur de la navbar */
  border: none; /* Supprime les bordures de l'iframe */
  object-fit: contain; /* Adapte le contenu à la taille de l'iframe sans déborder */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .pdf-viewer {
    height: calc(100vh - 60px); /* Ajustement spécifique pour les écrans mobiles */
  }

  h1 {
    font-size: 1.2em; /* Réduction de la taille de la police sur mobile */
  }
}

/* Assure le bon affichage en orientation portrait */
@media (orientation: portrait) {
  .pdf-viewer {
    height: calc(100vh - 50px); /* Hauteur ajustée en mode portrait */
  }
}

/* Assure le bon affichage en orientation paysage */
@media (orientation: landscape) {
  .pdf-viewer {
    height: calc(100vh - 45px); /* Hauteur ajustée en mode paysage */
  }
}
</style>

<template>
  <nav class="navbar">
    <router-link to="/" class="name">Nathan Tournache</router-link>

    <div class="social-links">
      <a href="https://www.linkedin.com/in/nathan-tournache" target="_blank" class="social-icon">
        <img src="@/assets/linkedin-icon.png" alt="LinkedIn" />
      </a>
      <a href="https://github.com/nathantournache" target="_blank" class="social-icon">
        <img src="@/assets/github-icon.png" alt="GitHub" />
      </a>
    </div>
  </nav>
</template>

<style scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(62, 56, 56, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  z-index: 20;
}

.name {
  font-size: 24px;
  font-weight: bold;
  color: #f4f4f4;
  text-decoration: none;
}

.social-links {
  position: absolute;
  right: 35px; /* Place les icônes à droite */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 10px;
}

.social-icon img {
  width: 24px;
  height: 24px;
}
</style>

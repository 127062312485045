<template>
  <div class="project">
    <div class="project-text">
      <!-- Partie gauche : Titre, description et mots-clés -->
      <h2>{{ project.title }}</h2>
      <p>{{ project.description }}</p>

      <!-- Affichage des mots-clés -->
      <div class="technologies">
        <span>Technologies : </span>
        <span v-for="(keyword, index) in project.keywords" :key="index" class="keyword">{{ keyword }}</span>
      </div>

      <!-- Liens vers le site et GitHub -->
      <div class="project-links">
        <a :href="project.siteLink" target="_blank">Voir le site</a>
        <a :href="project.githubLink" target="_blank">Voir sur GitHub</a>
      </div>
    </div>

    <!-- Partie droite : Photo affichée et flèche pour passer à la suivante -->
    <div class="project-image" @click="toggleFullScreen">
      <img :src="require(`@/assets/images/${project.images[currentImageIndex]}`)" alt="Project Image" class="preview" />
      <button class="next-btn" @click.stop="nextImage">❯</button>
    </div>

    <!-- Image en plein écran -->
    <div v-if="isFullScreen" class="fullscreen-overlay" @click="toggleFullScreen">
      <img :src="require(`@/assets/images/${project.images[currentImageIndex]}`)" alt="Full Project Image" class="fullscreen-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PreProject",
  props: {
    project: Object,
  },
  data() {
    return {
      currentImageIndex: 0, // L'index de l'image actuelle
      isFullScreen: false,  // Indicateur pour afficher l'image en plein écran
    };
  },
  methods: {
    nextImage() {
      if (this.currentImageIndex < this.project.images.length - 1) {
        this.currentImageIndex++;
      } else {
        this.currentImageIndex = 0; // Retour à la première image
      }
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    },
  },
};
</script>

<style scoped>
.project {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;
  box-sizing: border-box;
}

.project-text {
  width: 50%;
  text-align: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-text h2 {
  font-size: 3em;
  margin-bottom: 10px;
  text-align: center;
}

.project-text p {
  font-size: 1.2em;
  color: #847b7b;
  text-align: left;
}

.technologies {
  color: #f4f4f4;
  margin-top: 20px;
  font-size: 1.2em;
  text-align: justify; /* Ajout pour forcer les mots à bien se placer */
  word-wrap: break-word; /* Permet de couper les mots s'ils sont trop longs */
  overflow-wrap: break-word; /* Support supplémentaire pour casser les mots */
}

.keyword {
  margin-right: 10px;
  color: #847b7b;
  font-weight: bold;
  display: inline-block; /* Permet un comportement fluide pour éviter les dépassements */
  max-width: 100%; /* Empêche de dépasser horizontalement l'écran */
}


.project-links {
  margin-top: 20px;
  text-align: center;
}

.project-links a {
  margin: 30px;
  text-decoration: none;
  color: #847b7b;
  font-weight: bold;
}

.project-links a:hover {
  text-decoration: underline;
}

.project-image {
  width: 45%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-image img.preview {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 10px;
}

.next-btn {
  position: absolute;
  bottom: 5%;
  right: 10%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2em;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
}

.next-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  border-radius: 10px;
}

/* Version mobile */
@media (max-width: 768px) {
  .projects-section {
    height: auto;
    overflow-y: scroll; /* Scroll vertical fluide */
    scroll-behavior: smooth;
  }
  .technologies {
    text-align: left; /* Garde un alignement fluide mais pas central */
  }
  .project {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    height: auto;
  }

  .project-text {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  .project-image {
    width: 100%;
    margin-top: 10px;
  }

  .project-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .project-text h2 {
    font-size: 2em; /* Réduit la taille des titres pour mobile */
  }

  .project-text p {
    text-align: justify; /* Justifie le texte des descriptions */
  }
}

</style>

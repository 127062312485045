<template>
  <div id="app">
    <!-- Composant Navbar -->
    <AppNavbar />

    <!-- Contenu principal de la page -->
    <router-view />

    <!-- Composant Phone -->
    <PhoneButton />
  </div>
</template>

<script>
// Importation des composants Navbar et PhoneButton
import PhoneButton from "@/components/PhoneButton.vue";
import AppNavbar from "./components/AppNavbar.vue";

export default {
  name: "App",
  components: {
    AppNavbar,
    PhoneButton,
  },
};
</script>

<style>
/* Désactiver le scroll sur toutes les vues */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Désactive le scroll */
  font-family: 'Poppins', sans-serif; /* Appliquer la typographie globale */
  background-color: #121212; /* Fond principal sombre */
  color: #FFFFFF; /* Texte par défaut blanc */
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Ajout de styles globaux pour les titres */
h1, h2, h3 {
  color: #FFFFFF; /* Couleur blanche pour les titres */
  font-weight: 800; /* Poids fort pour les titres */
  margin: 0;
}

p {
  color: #B0B0B0; /* Couleur pour le texte secondaire */
  font-weight: 400;
  line-height: 1.6;
}

/* Lien interactif */
a {
  color: #2D2D2D;
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  color: #66BB6A;
  text-decoration: underline;
}
</style>

<template>
  <div class="projects-section" @scroll="handleScroll">
    <!-- Bouton unique servant à afficher la liste des mots-clés -->
    <div class="selected-keyword" @click="toggleKeywords">
      {{ selectedKeyword !== 'aucun' ? selectedKeyword : 'Tous les projets' }}
    </div>

    <!-- Liste des mots-clés visible après clic sur le bouton -->
    <div v-if="isKeywordsVisible" class="keywords-list">
      <span 
        v-for="keyword in keywords" 
        :key="keyword" 
        class="keyword" 
        :class="{ active: selectedKeyword === keyword }" 
        @click="selectKeyword(keyword)">
        {{ keyword }}
      </span>
      <span 
        class="keyword" 
        :class="{ active: selectedKeyword === 'aucun' }" 
        @click="selectKeyword('aucun')">Tous les projets</span>
    </div>

    <!-- Affichage des projets filtrés -->
    <div class="projects">
      <PreProject
        v-for="(project, index) in filteredProjects"
        :key="project.id"
        :project="project"
        :isVisible="currentProjectIndex === index"
        class="project-item"
      />
    </div>
  </div>
</template>

<script>
import { projects } from "@/data/projects.js";
import PreProject from "@/components/PreProject.vue";

export default {
  name: "ProjectsSection",
  components: {
    PreProject,
  },
  data() {
    return {
      projects: projects,
      filteredProjects: [],
      currentProjectIndex: 0,
      selectedKeyword: "aucun",
      keywords: ["vue", "javascript", "python", "flask", "responsive", "backend", "frontend", "data science", "robotique", "arduino", "gestion de projet"],
      isKeywordsVisible: false,
    };
  },
  created() {
    this.filteredProjects = this.projects;
  },
  methods: {
    handleScroll(event) {
      const scrollPosition = event.target.scrollTop;
      const nextIndex = Math.floor(scrollPosition / window.innerHeight);
      if (nextIndex !== this.currentProjectIndex && nextIndex < this.filteredProjects.length) {
        this.currentProjectIndex = nextIndex;
      }
    },
    filterProjects() {
      if (this.selectedKeyword === "aucun") {
        this.filteredProjects = this.projects;
      } else {
        this.filteredProjects = this.projects.filter((project) =>
          project.keywords.includes(this.selectedKeyword)
        );
      }
    },
    toggleKeywords() {
      this.isKeywordsVisible = !this.isKeywordsVisible;
    },
    selectKeyword(keyword) {
      this.selectedKeyword = keyword;
      this.filterProjects();
      this.isKeywordsVisible = false; // Ferme la liste après sélection
    },
  },
};
</script>

<style scoped>
.projects-section {
  height: 100vh;
  overflow-y: scroll; /* Défilement vertical */
  scroll-behavior: smooth;
}

.selected-keyword {
  position: fixed;
  top: 70px; /* Ajusté pour rester au-dessus */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  cursor: pointer;
  color: #2d2d2d;
}

.selected-keyword:hover {
  background-color: #f4f4f4;
}

.keywords-list {
  position: fixed;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 30;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.keyword {
  display: block;
  padding: 5px 0;
  cursor: pointer;
  color: #2d2d2d;
  font-weight: bold;
}

.keyword:hover {
  color: #847b7b;
}

.keyword.active {
  color: white;
  background-color: #2d2d2d;
  padding: 5px 10px;
  border-radius: 5px;
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 40px; /* Espacement vertical */
}

.project-item {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.project-item:nth-child(n + 1) {
  opacity: 1;
}

/* Mobile */
@media (max-width: 768px) {
  .projects-section {
    height: auto;
    overflow-y: auto; /* Permet de scroller */
    padding-top: 120px; /* Ajustement pour éviter chevauchement */
  }

  .projects {
    display: flex;
    flex-direction: column; /* Affiche les projets l'un sous l'autre */
    gap: 20px;
  }

  .project-item {
    width: 100%;
    height: auto;
  }
}

</style>
